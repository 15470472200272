import { createContext, useContext, useState } from 'react';
import React from 'react';
import i18n from '../i18n';

export interface Session {
    token?:string;
    redirectPathOnAuthentication?: string;
    language: string;
};

const token = localStorage.getItem("currentUser");
export const initialSession: Session = token ? { token: token.substring(1, token.length-1), language: i18n.language } : {language: i18n.language};

export const SessionContext = createContext<[Session, (session: Session) => void]>([initialSession, () => { }]);
export const useSessionContext = () => useContext(SessionContext);

export const SessionContextProvider: React.FC = (props) => {
    const [sessionState, setSessionState] = useState(initialSession);
    const defaultSessionContext: [Session, typeof setSessionState] = [sessionState, setSessionState];

    return (
        <SessionContext.Provider value={defaultSessionContext}>
            {props.children}
        </SessionContext.Provider>
    );
};