import { Session } from '../contexts/SessionContext';
import { createAccountEndpoint } from './account-management/endpoints';
import { AccountCreateRequest, AccountCreateResponse } from './account-management/types';
import { apiUrl } from './config';
import { genericPost } from './genericService';

export type ResponseMessage = { status: "error" | "success" | "info" | "warning", message: string };

const login = (email: string, password: string, [sessionContext, updateSessionContext]: [Session, (session: Session) => void]): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": email, "password": password })
    };

    return fetch(`${apiUrl}/admin/sign-in`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            }
            return res.json();
        })
        .then(res => {
            localStorage.setItem('currentUser', JSON.stringify(res.authenticationToken));
            updateSessionContext({
                ...sessionContext,
                token: res.authenticationToken
            });
            return { status: "success", message: "Successfully logged in. Redirecting..." }
        })
        .catch(res => {
            switch (res.status) {
                case 401: {
                    return { status: "error", message: "Wrong email or password" };
                };
                case 400: {
                    return { status: "error", message: "Make sure to input both email and password" };
                };
                case 423: {
                    return { status: "error", message: "This account has been blocked" };
                };
                case 429: {
                    return { status: "error", message: "This account has been blocked" };
                };
                default: {
                    return { status: "error", message: "Soemthing went wrong" };
                };
            };
        });
};

const logout = ([sessionContext, updateSessionContext]: any) => {
    localStorage.removeItem('currentUser');
    updateSessionContext({ ...sessionContext, token: undefined })
};

const register = async (dept: AccountCreateRequest, token?: string): Promise<any> => {
    return genericPost<AccountCreateResponse>(createAccountEndpoint(), dept, token)
        .then((res: any) => {
            return {
                status: "success",
                message: "Account successfully created"
            }
        })
        .catch(res => {
            console.log(res)
            switch (res || res.status) {
                case 401: return { status: "error", message: "You are not logged in" };
                case 403: return { status: "error", message: "You are not authorized" };
                case 409: return { status: "error", message: "Account is already registered" };
                case 500: return { status: "error", message: "Internal Server Error" };
                case 503: return { status: "error", message: "Service unavailable" };
                default: return { status: "error", message: "Something's wrong" };
            };
        });
};

const setPassword = (id: number, guid: string, password: string): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "stamp": guid,
            "password": password
        })
    };

    return fetch(`${apiUrl}/sign-up/${id}/confirmation`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            };
            return res.json();
        })
        .then(() => ({ status: "success", message: "Password set" }))
        .catch(res => {
            switch (res.status) {
                case 400: {
                    return { status: "error", message: "You have to fill in all spaces" };
                };
                case 401: {
                    return { status: "error", message: "Invalid verification code" };
                };
                case 409: {
                    return { status: "error", message: "Account is already confirmed" };
                };
                case 410: {
                    return { status: "error", message: "Verification code has expired" };
                };
                default: {
                    return { status: "error", message: "Something went wrong" };
                };
            };
        });
};

const requestChangePassword = (email: string): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "email": email,
        })
    };

    return fetch(`${apiUrl}/password-reset`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            };
            return res.json();
        })
        .then(() => ({ status: "success", message: `Email sent to ${email}` }))
        .catch(res => {
            switch (res.status) {
                case 400: {
                    return { status: "error", message: "Incorrect email" };
                };
                default: {
                    return { status: "error", message: "Something went wrong" };
                };
            };
        });
};

const changePassword = (id: number, guid: string, newPassword: string): Promise<any> => {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "stamp": guid,
            "password": newPassword,
        })
    };

    return fetch(`${apiUrl}/password-reset/${id}/confirmation`, requestOptions)
        .then(res => {
            if (!res.ok) {
                throw res;
            };
            return res.json();
        })
        .then(() => ({ status: "success", message: `Password set` }))
        .catch(res => {
            switch (res.status) {
                case 400: {
                    return { status: "error", message: "Incorrect password" };
                };
                case 403: {
                    return { status: "error", message: "Invalid verification code" };
                };
                case 410: {
                    return { status: "error", message: "Verification code has expired" };
                };
                default: {
                    return { status: "error", message: "Something went wrong" };
                };
            };
        });
};

export const authenticationService = {
    login,
    logout,
    register,
    setPassword,
    requestChangePassword,
    changePassword
};