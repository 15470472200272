import React, { useEffect } from 'react';
import { DeviceGetAllForTableResponse } from '../services/device-management/types';
import { useSessionContext } from '../contexts/SessionContext';
import { deviceService } from '../services/device-management/device.service';
import { useSnackbar, VariantType } from 'notistack';
import { getGetErrorText } from '../services/genericService';
import { AppBar, Box, Button, Dialog, Grid, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import DevicesTable from './DevicesTable';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import AddDeviceForm from './AddDeviceForm';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function MyDevicesView() {
    const classes = useStyles();
    const [sessionContext] = useSessionContext();
    const { enqueueSnackbar } = useSnackbar();
    const [t] = useTranslation();
    const [rows, setRows] = React.useState<DeviceGetAllForTableResponse[]>([]);
    const [addOpen, setAddOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [editOpen, setEditOpen] = React.useState(false);
    const [editedDevice, setEditedDevice] = React.useState<string>('');

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        deviceService.getAllForTable(sessionContext.token)
            .then(res => {
                setRows(res);
            })
            .catch(err => {
                handleSnackBar(getGetErrorText(err), "error");
            });
    };

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClickEditOpen = (key: string) => {
        setEditedDevice(key);
        setEditOpen(true);
    };

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        setEditedDevice('');
        getData();
    };

    const containsSearchTerm = (device: DeviceGetAllForTableResponse, searchTerm: string): boolean => {
        const term = searchTerm.toLowerCase();

        return device.serialNumber.toLowerCase().includes(term) ||
            device.accountName.toLowerCase().includes(term);
    };

    return (
        <React.Fragment>
            <Box p={3}>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item container alignItems="center" xs>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                                {t("Add device")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={2}>
                    <DevicesTable
                        rows={searchTerm ? rows.filter(account => containsSearchTerm(account, searchTerm)) : rows}
                        handleClickEditOpen={handleClickEditOpen}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                    />
                </Box>

                <Dialog fullScreen open={addOpen} onClose={handleClose} >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                {t("Add device")}
                            </Typography>
                            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <AddDeviceForm handleClose={handleClose} />
                </Dialog>

                <Dialog fullScreen open={editOpen} onClose={handleClose} >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                {t("Edit device")}
                            </Typography>
                            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <AddDeviceForm editedDevice={editedDevice} handleClose={handleClose} />
                </Dialog>
            </Box>
      </React.Fragment>
    );
}
