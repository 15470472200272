import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

interface TableSearchbarProps {
    type: "accounts active" | "accounts blocked" | "devices";
    searchTerm: string;
    setSearchTerm: (newTerm: string) => void;
};

export default function TableSearchbar(props: TableSearchbarProps) {
    const { type, searchTerm, setSearchTerm } = props;

    const [t] = useTranslation();

    const getPhrase = (): string => {
        switch (type) {
            case "accounts active":
            case "accounts blocked": return "account";
            case "devices": return "device";
            default: return type;
        }
    }

    function handleOnChange(event: React.ChangeEvent<{ value: unknown }>): void {
        setSearchTerm(event.target.value as string);
    };

    return (
        <React.Fragment>
            <TextField
                fullWidth
                variant="standard"
                className="searchTerm"
                label={t(`Find ${getPhrase()}`)}
                name="searchTerm"
                value={searchTerm}
                onChange={handleOnChange}
                style={{ maxWidth: 250 }}
            />
        </React.Fragment>
    );
}