import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import DevicesIcon from '@material-ui/icons/Devices';
import LockIcon from '@material-ui/icons/Lock';
import FlagIcon from './FlagIcon';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { useSessionContext } from '../contexts/SessionContext';
import { authenticationService } from '../services/authentication.service';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      background: 'rgb(159,149,137) linear-gradient(74deg, rgba(159,149,137,1) 4%, rgba(249,169,72,1) 25%, rgba(31,157,181,1) 93%)',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    menuItem: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
      },
    }
  }),
);

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

export default function SideMenu(props: any) {
  const theme = useTheme();
  const classes = useStyles(props);
  const location = useLocation();
  const [sessionContext, updateSessionContext] = useSessionContext();
  const [open, setOpen] = React.useState(false);
  const [t, i18n] = useTranslation();
  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
  }

  const getIcon = (key: string): JSX.Element => {
    switch (key) {
      case "PersonIcon": return <PersonIcon />;
      case "DeviceIcon": return <DevicesIcon />;
      default: return <ClearIcon />
    }
  };

  const listItems: { href: string, text: string, iconKey: string }[] = [
    {
      href: "/devices",
      text: "Devices",
      iconKey: "DeviceIcon",
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Protectimo
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>

          <ListItemLink className={classes.menuItem} component={Link} to="/" selected={location.pathname === "/"}>
            <ListItemIcon > {getIcon("PersonIcon")} </ListItemIcon>
            <ListItemText primary={t("Accounts")} />
          </ListItemLink>

          {listItems.map((item, index) => (
            <ListItemLink className={classes.menuItem} component={Link} key={index} to={item.href} selected={location.pathname.startsWith(item.href)}>
              <ListItemIcon > {getIcon(item.iconKey)} </ListItemIcon>
              <ListItemText primary={t(item.text)} />
            </ListItemLink>
          ))}

          <ListItemLink className={classes.menuItem} button onClick={() => authenticationService.logout([sessionContext, updateSessionContext])}>
            <ListItemIcon> <LockIcon /> </ListItemIcon>
            <ListItemText primary={t('Log out')} />
          </ListItemLink>

          <Divider />

          <ListItemLink className={classes.menuItem} button onClick={() => changeLanguage('en')} >
            <ListItemIcon>
              <FlagIcon name={'union_jack_square'} />
            </ListItemIcon>
            <ListItemText primary={'English'} />
          </ListItemLink>

          <ListItemLink className={classes.menuItem} button onClick={() => changeLanguage('pl')} >
            <ListItemIcon>
              <FlagIcon name={'poland_flag'} />
            </ListItemIcon>
            <ListItemText primary={'Polski'} />
          </ListItemLink>

        </List>
      </Drawer>
    </div>
  );
}
