import React, { FormEvent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useInput } from '../hooks/input-hook';
import { RouteComponentProps } from 'react-router-dom';
import { authenticationService, ResponseMessage } from '../services/authentication.service';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        maxWidth: "500px",
    },
    gridBox: {
        verticalAlign: "middle",
        marginTop: "auto",
        marginBottom: "auto",
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface MatchParams {
    id: string;
    guid: string;
};

interface SetNewAccountPasswordViewProps extends RouteComponentProps<MatchParams> {
};

export default function SetNewAccountPasswordView(props: SetNewAccountPasswordViewProps) {
    const { id, guid } = props.match.params;
    const { value: password, bind: bindPassword, reset: resetPassword } = useInput("");
    const { value: repeatPassword, bind: bindrepeatPassword, reset: resetrepeatPassword } = useInput("");
    const [responseMessage, setResponseMessage] = React.useState<null | ResponseMessage>(null)

    const classes = useStyles();

    const clearMessage = () => {
        setResponseMessage(null);
    };

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();
        if (password !== repeatPassword) {
            setResponseMessage({ status: "error", message: "Both passwords need to be the same" });
        } else {
            const res = await authenticationService.setPassword(parseInt(id), guid, password);
            setResponseMessage(res);
            resetPassword();
            resetrepeatPassword();
        };
    };

    const {t} = useTranslation();

    return (
        <Box className={classes.gridBox}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {t("Set your password")}
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleOnSubmit} onChange={clearMessage}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label={t("New password")}
                    type="password"
                    name="password"
                    autoFocus
                    {...bindPassword}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="repeat-password"
                    label={t("Repeat new password")}
                    type="password"
                    name="repeat-password"
                    autoFocus
                    {...bindrepeatPassword}
                />
                {responseMessage && <Alert severity={responseMessage.status} >{responseMessage.message}</Alert>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    {t("Confirm")}
                </Button>
            </form>
        </Box>
    );
};