import React from 'react';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useSessionContext } from './contexts/SessionContext';
import ProtectedRoute, { ProtectedRouteProps } from './components/ProtectedRoute';
import AuthorizedAppLayout from './components/AuthorizedAppLayout';
import UnauthorizedAppLayout from './components/UnauthorizedAppLayout';
import Accounts from './components/Accounts';
import SetNewAccountPasswordView from './views/SetNewAccountPasswordView';
import SignInView from './views/SignInView';
import ResetPasswordView from './views/ResetPasswordView';
import ChangePasswordView from './views/ChangePasswordView';
import { ReactQueryClientProvider } from './lib/wrappers';
import MyDevicesView from './components/Devices';

const App: React.FC = () => {
  const [sessionContext] = useSessionContext();
  const renderComponent = (redirectPath: string) => <Redirect to={{ pathname: redirectPath }} />;
  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: !!sessionContext.token,
    isAllowed: true,
    authenticationPath: '/login',
    restrictedPath: '/'
  };

  return (
    <Router>
      <ReactQueryClientProvider>

        {!!sessionContext.token
          ? <AuthorizedAppLayout >

            <Switch>
              <ProtectedRoute {...defaultProtectedRouteProps} exact path="/" component={Accounts} />
              <ProtectedRoute {...defaultProtectedRouteProps} path="/devices" component={MyDevicesView} />
              <Route component={() => renderComponent('/')} />
            </Switch>

          </AuthorizedAppLayout>
          : <UnauthorizedAppLayout >
            <Switch>
              <Route path="/login" component={SignInView} />
              <Route path="/reset" component={ResetPasswordView} />
              <Route path="/change/:id/:guid" component={ChangePasswordView} />
              <Route path='/confirm/:id/:guid' component={SetNewAccountPasswordView} />
              <Route component={() => renderComponent('/login')} />
            </Switch>
          </UnauthorizedAppLayout>}

      </ReactQueryClientProvider>
    </ Router>
  );
}

export default App;