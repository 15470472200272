import React, { FormEvent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { authenticationService, ResponseMessage } from '../services/authentication.service';
import { useSessionContext } from '../contexts/SessionContext';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        maxWidth: "500px",
    },
    gridBox: {
        verticalAlign: "middle",
        marginTop: "auto",
        marginBottom: "auto",
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonEN: {
        backgroundImage: 'url("/images/union_jack.png")',
        backgroundSize: "100%",
        height: "30px",
    },
    buttonPL: {
        backgroundImage: 'url("/images/poland_flag.png")',
        backgroundSize: "contain",
        height: "30px",
    },
}));

export default function SignInView() {
    const history = useHistory();
    const [sessionContext, updateSessionContext] = useSessionContext();
    const [signInResult, setSignInResult] = React.useState<null | ResponseMessage>(null);
    const [state, setState] = React.useState({
        email: '',
        password: ''
    });

    const {t, i18n} = useTranslation();

    const changeLanguage = (key:string) :void => {
        i18n.changeLanguage(key);
    };

    const { email, password } = state;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({ ...state, [event.target.name]: event.target.value });
        setSignInResult(null);
    };

    const classes = useStyles();

    async function handleOnSubmit(e: FormEvent): Promise<any> {
        e.preventDefault();

        const result = await authenticationService.login(email, password, [sessionContext, updateSessionContext]);
        result.status === "error" ? setSignInResult(result) : setTimeout(() => history.push("/"), 500);
    };


    return (
        <Box className={classes.gridBox}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {t("Sign in")}
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("Email Address")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={handleOnChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("Password")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleOnChange}
                />
                <Grid container >
                    <Grid item xs>
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label={t("Remember me")}
                        />
                    </Grid>
                    <Grid container item xs justify={"flex-end"} >
                        <Box mt={1}>
                            <Link href="/reset" variant="body2">
                                {t("Forgot password")}
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                {signInResult &&
                    <>
                        <Box mt={2} />
                        <Alert severity={signInResult.status}>{signInResult.message}</Alert>
                    </>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    {t("Sign in")}
                </Button>
                <Box mt={2} />
                <Grid container>
                    <Grid container item xs justify={"center"} >
                        <Button
                            className={classes.buttonEN}
                            variant="contained"
                            onClick={() => changeLanguage('en')}
                        />
                    </Grid>
                    <Grid container item xs justify={"center"} >
                        <Button
                            className={classes.buttonPL}
                            variant="contained"
                            onClick={() => changeLanguage('pl')}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>

    );
}

