import { genericGet, genericPost, genericPut } from "../genericService";
import { createDeviceEndpoint, getDeviceEndpoint, getDevicesEndpoint, updateDeviceEndpoint } from "./endpoints";
import {  DeviceCreateRequest, DeviceCreateResponse, DeviceGetAllForTableResponse, DeviceGetAllResponse, DeviceGetOneResponse, DeviceUpdateRequest, DeviceUpdateResponse } from "./types";

const getAll = async (token?: string): Promise<DeviceGetAllResponse[]> => {
  return genericGet<DeviceGetAllResponse[]>(getDevicesEndpoint(), {}, token);
}

const getOne = async (uuid: string, token?: string): Promise<DeviceGetOneResponse> => {
  return genericGet<DeviceGetOneResponse>(getDeviceEndpoint(uuid), {}, token);
}

const createDevice = (device: DeviceCreateRequest, token?: string): Promise<DeviceCreateResponse> => {
  return genericPost<DeviceCreateResponse>(createDeviceEndpoint(), device, token);
};

const updateDevice = (deviceUuid: string, device: DeviceUpdateRequest, token?: string): Promise<DeviceUpdateResponse> => {
  return genericPut<DeviceUpdateResponse>(updateDeviceEndpoint(deviceUuid), device, token);
};

const getAllForTable = async (token?: string): Promise<DeviceGetAllForTableResponse[]> => {
  return getAll(token)
      .then(res => {
          return convertGetAllToTable(res);
      });
};

const convertGetAllToTable = (list: DeviceGetAllResponse[]): DeviceGetAllForTableResponse[] => {
  const res: DeviceGetAllForTableResponse[] = [];
  list.forEach(device => {
      res.push({
          uuid: device.uuid,
          serialNumber: device.serialNumber,
          accountUuid: device.accountUuid,
          accountName: device.accountName,
          type: device.type,
          isActivated: device.isActivated ? 'Yes' : 'No',
      });
  });
  return res;
}

export const deviceService = {
    getAll,
    getOne,
    createDevice,
    updateDevice,
    getAllForTable,
}
