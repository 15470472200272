import React, { FormEvent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { authenticationService, ResponseMessage } from '../services/authentication.service';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    maxWidth: "500px",
  },
  gridBox: {
    verticalAlign: "middle",
    marginTop: "auto",
    marginBottom: "auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPasswordView() {
  const [email, setEmail] = React.useState('');
  const [result, setResult] = React.useState<null | ResponseMessage>(null);

  const classes = useStyles();

  async function handleOnSubmit(e: FormEvent): Promise<any> {
    e.preventDefault();
    const res = await authenticationService.requestChangePassword(email);
    if(res.status==="success") setEmail('');
    setResult(res);
  };

  const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setResult(null);
  };
  
  const {t} = useTranslation();

  return (
    <Box className={classes.gridBox}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("Reset password")}
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("Email Address")}
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={handleInputChange}
        />
        {!!result
          && <>
            <Box mt={2} />
            <Alert severity={result.status}>{result.message}</Alert>
          </>}
        <Box mt={1}>
          <Link href="/login" variant="body2">
            {t("Back to login")}
          </Link>
        </Box>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t("Send")}
        </Button>
      </form>
    </Box>
  );
}