import React from 'react';

type FlagIconProps = {"name":string};

function FlagIcon (props: FlagIconProps) {
    const name = props.name;

    return (
        <img alt={name} src={`/images/${name}.png`} width='24px' height='24px' />
    )
}

export default FlagIcon;