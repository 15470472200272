import { apiUrl } from "../../config";

const getDevicesEndpoint = () => `${apiUrl}/admin/device-controllers`;
const getDeviceEndpoint = (uuid: string) => `${apiUrl}/admin/device-controllers/${uuid}`;
const createDeviceEndpoint = () => `${apiUrl}/admin/device-controllers`;
const updateDeviceEndpoint = (uuid: string) => `${apiUrl}/admin/device-controllers/${uuid}`;

export {
  getDevicesEndpoint,
  getDeviceEndpoint,
  createDeviceEndpoint,
  updateDeviceEndpoint,
};
