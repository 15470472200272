import { genericGet, genericPut } from "../genericService";
import { getAccountEndpoint, getAccountsEndpoint, updateAccountEndpoint } from "./endpoints";
import { AccountGetAllResponse, AccountGetResponse, AccountUpdateRequest, AccountUpdateResponse } from "./types";

export interface AccountData {
    uuid: string;
    name: string;
    NIP: string;
    plan: string;
    email: string;
    status: "Activated" | "Not activated";
    createdAt: string;
}

const getAll = async(isActive: boolean, token?: string, searchTerm?: string): Promise<AccountData[]> => {
    const data = await genericGet<AccountGetAllResponse[]>(getAccountsEndpoint(), {}, token);

    return data
        .filter(row => row.isEnabled === isActive)
        .filter(row => {
            if (!searchTerm) return true;

            const term = searchTerm.toLowerCase();
            return (
                row.name.toLowerCase().includes(term) ||
                row.email.toLowerCase().includes(term) ||
                row.taxId.toLowerCase().includes(term)
            );
        })
        .map(row => ({
            uuid: row.uuid,
            name: row.name,
            NIP: row.taxId,
            plan: row.plan,
            email: row.email,
            status: row.isActivated ? "Activated" : "Not activated",
            createdAt: (new Date(row.creationDate)).toLocaleDateString()
        }));
};

const getAccount = async(userUuid: string, token?: string): Promise<AccountGetResponse | undefined> => {
    return await genericGet<AccountGetResponse>(getAccountEndpoint(userUuid), {}, token);
}

const updateAccount = (userUuid: string, dept: AccountUpdateRequest, token?: string): Promise<any> => {
    return genericPut<AccountUpdateResponse>(updateAccountEndpoint(userUuid), dept, token)
        .then(() => ({
            status: "success",
            message: "Account successfully edited"
        }))
        .catch(res => {
            switch (res.status) {
                case 401: return { status: "error", message: "You are not logged in" };
                case 403: return { status: "error", message: "You are not authorized" };
                case 409: return { status: "error", message: "This NIP or email is already registered" };
                case 500: return { status: "error", message: "Internal Server Error" };
                case 503: return { status: "error", message: "Service unavailable" };
                default: return { status: "error", message: "Something's wrong" };
            };
        });
}

export const accountsService = {
    getAll,
    getAccount,
    updateAccount,
}