import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Grid, Switch, Button, Dialog, IconButton, Toolbar, Typography, AppBar, Snackbar } from '@material-ui/core';
import AccountsTable from './AccountsTable';
import { useTranslation } from 'react-i18next';
import { AccountData, accountsService } from '../services/account-management/accounts.service';
import TableSearchbar from './TableSearchbar';
import AddAccountForm from './AddAccount';
import { useSessionContext } from '../contexts/SessionContext';
import { ResponseMessage } from '../services/authentication.service';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    appBarSpacer: theme.mixins.toolbar,
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function Accounts() {
    const [sessionContext] = useSessionContext();
    const classes = useStyles();
    const [blockedChecked, setBlockedChecked] = React.useState(false);
    const [addOpen, setAddOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [rows, setRows] = React.useState<AccountData[]>([]);
    const [editedAccountId, setEditedAccountsId] = React.useState<string>("");
    const [selected, setSelected] = React.useState<string[]>([]);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [addAccountResponse, setAddAccountResponse] = React.useState<null | ResponseMessage>(null);

    useEffect(() => {
        refreshData();
    }, [blockedChecked, searchTerm]);

    const refreshData = () => {
        accountsService.getAll(!blockedChecked, sessionContext.token, searchTerm)
            .then(newRows => setRows(newRows))
            .catch(err => console.log(err));
    }

    const [t] = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBlockedChecked(event.target.checked);
    };

    const handleClickEditOpen = (uuid: string) => {
        setEditedAccountsId(uuid);
        setEditOpen(true);
    };

    const handleClickAddOpen = () => {
        setAddOpen(true);
    };

    const handleClose = () => {
        setAddOpen(false);
        setEditOpen(false);
        refreshData();
    };

    return (
        <Box m={3}>
            <Box display={"block"}>
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>{t('Active')}</Grid>
                    <Grid item>
                        <Switch color='primary' checked={blockedChecked} onChange={handleChange} name="activeChecked" />
                    </Grid>
                    <Grid item>{t('Blocked')}</Grid>
                </Grid>
            </ Box>
            <Box mt={1}>
                <Grid container justify="space-between">
                    <Grid item container xs={6}>
                        <Button variant="contained" color="primary" onClick={handleClickAddOpen}>
                            {t("Add account")}
                        </Button>
                    </Grid>
                    <Grid item container xs={6} justify="flex-end">
                        <Grid item>
                            <TableSearchbar type={blockedChecked ? "accounts blocked" : "accounts active"} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
            <Box mt={3}>
                <AccountsTable
                    handleClickEditOpen={handleClickEditOpen}
                    selected={selected}
                    setSelected={setSelected}
                    rows={rows} />
            </Box>

            <Dialog fullScreen open={addOpen} onClose={handleClose} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Add account")}
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AddAccountForm handleClose={handleClose} setAddAccountResponse={setAddAccountResponse} />
            </Dialog>

            <Dialog fullScreen open={editOpen} onClose={handleClose} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t("Edit account")}
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <AddAccountForm editedClientUuid={editedAccountId} handleClose={handleClose} setAddAccountResponse={setAddAccountResponse} />
            </Dialog>

            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={!!addAccountResponse}
                onClose={() => setAddAccountResponse(null)}
                autoHideDuration={6000}>
                    <Alert severity={addAccountResponse?.status}>
                    {t(addAccountResponse ? addAccountResponse.message : "")}
                    </Alert>
            </Snackbar>
        </Box>
    );
}