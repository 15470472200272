import { apiUrl } from "../../config";

const getAccountsEndpoint = () => `${apiUrl}/accounts`;
const getAccountEndpoint = (uuid: string) => `${apiUrl}/accounts/${uuid}`;
const createAccountEndpoint = () => `${apiUrl}/accounts`;
const createAccountConfirmEndpoint = (uuid: string) => `${apiUrl}/sign-up/${uuid}/confirmation`;
const updateAccountEndpoint = (uuid: string) => `${apiUrl}/accounts/${uuid}`;

export {
  getAccountsEndpoint,
  getAccountEndpoint,
  createAccountEndpoint,
  createAccountConfirmEndpoint,
  updateAccountEndpoint,
};