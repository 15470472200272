import { Box, Button, Container, CssBaseline, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useSnackbar, VariantType } from "notistack";
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../contexts/SessionContext";
import { useInput, useSelectInput } from "../hooks/input-hook";
import { accountsService } from "../services/account-management";
import { AccountGetAllOnlyNamesResponse } from "../services/account-management/types";
import { deviceService } from "../services/device-management/device.service";
import { DeviceCreateRequest } from "../services/device-management/types";
import { getCreateErrorText, getGetErrorText } from "../services/genericService";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
    },
    switchLabel: {
        padding: theme.spacing(1),
    },
    innerPaper: {
        width: "100%",
        margin: '16px 8px 8px 8px',
    }
}));

interface AddDeviceFormProps {
    editedDevice?: string;
    handleClose: () => void;
};

export default function AddDeviceForm(props: AddDeviceFormProps) {
    const classes = useStyles();
    const [sessionContext] = useSessionContext();
    const { editedDevice, handleClose } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [t] = useTranslation();
    const [accounts, setAccounts] = useState<AccountGetAllOnlyNamesResponse[]>([]);
    const { value: account, setValue: setAccount, bind: bindAccount } = useSelectInput('');
    const { value: serialNumber, setValue: setSerialNumber, bind: bindSerialNumber } = useInput('');
    const { value: type, setValue: setType, bind: bindType } = useSelectInput('');

    const handleSnackBar = (message: string, variant: VariantType) => {
        enqueueSnackbar(message, { variant });
    };

    useEffect(() => {
        accountsService.getAll(true, sessionContext.token)
            .then(res => res.map(({ uuid, name }) => ({ uuid, name })))
            .then(newRows => setAccounts(newRows))
            .catch(err => handleSnackBar(getGetErrorText(err), 'error'));
        
        if (editedDevice) {
            deviceService.getOne(editedDevice, sessionContext.token)
                .then(res => {
                    setAccount(res.accountUuid)
                    setSerialNumber(res.serialNumber)
                    setType(res.type.slice(1));
                })
                .catch(err => handleSnackBar(getGetErrorText(err), "error"));
        }
    }, [editedDevice]);

    function handleOnSubmit(e: FormEvent) {
        e.preventDefault();

        const dept: DeviceCreateRequest = {
            accountUuid: account,
            serialNumber,
            type: type === '1' ? "k1" : (type === '2' ? "k2" : "k4"),
        }

        if (editedDevice) {
            deviceService.updateDevice(editedDevice, dept, sessionContext.token)
                .then(() => {
                    handleSnackBar(t('Successfully edited'), 'success');
                    handleClose();
                })
                .catch(err => {
                    handleSnackBar(t(getCreateErrorText(err)), 'error');
                });
        } else {
            deviceService.createDevice(dept, sessionContext.token)
                .then(() => {
                    handleSnackBar(t('Successfully added'), 'success');
                    handleClose();
                })
                .catch(err => {
                    handleSnackBar(t(getCreateErrorText(err)), 'error');
                });
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Box pb={4} mt={2}>
                    <Typography component="h1" variant="h5">
                        {t("Insert device data")}
                    </Typography>
                </Box>
                <form className={classes.form} onSubmit={handleOnSubmit}>
                    <FormControl className={classes.formControl} required>
                        <InputLabel id="account-select-label">{t("Account")}</InputLabel>
                        <Select
                            labelId="account-select-label"
                            id="account-select"
                            {...bindAccount}
                        >
                            <MenuItem value='' disabled>{t("Choose account")}</MenuItem>
                            {accounts.map(account => (
                                <MenuItem key={account.uuid} value={account.uuid}>{account.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            required
                            id="serial-number-input"
                            label={t("Device serial number")}
                            {...bindSerialNumber}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl} required>
                        <InputLabel id="type-select-label">{t("Device type")}</InputLabel>
                        <Select
                            labelId="type-select-label"
                            id="type-select"
                            {...bindType}
                        >
                            <MenuItem value='' disabled>{t("Choose device type")}</MenuItem>
                            <MenuItem value='1'>{t("1 entrance")}</MenuItem>
                            <MenuItem value='2'>{t("2 entrance")}</MenuItem>
                            <MenuItem value='4'>{t("4 entrance")}</MenuItem>
                        </Select>
                    </FormControl>

                    <Box mt={2} />
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                {t(editedDevice ? "Save" : "Add device")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
