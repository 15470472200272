import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { authorizationPrefix } from "./config";
import { ApiHttpErrorType } from "./types";

const createHeadersWithToken = (token?: string) =>
  token
    ? {
      Authorization: `${authorizationPrefix} ${token}`,
    }
    : {};

const genericDelete = (url: string, token?: string): Promise<{ status: number, statusText: string }> => {
  const headers = createHeadersWithToken(token);
  return axios
    .delete(url, { headers })
    .then((response: AxiosResponse<{ status: number, statusText: string }>) => {
      return { status: response.status, statusText: response.statusText };
    })
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
}

const genericPost = <ResponseType>(
  endpoint: string,
  postParameters?: { [key: string]: unknown } | FormData,
  token?: string
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return axios
    .post(endpoint, postParameters, { headers })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw error.response.status;
    });
};

const genericGet = <ResponseType>(
  endpoint: string,
  config?: AxiosRequestConfig | undefined,
  token?: string
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return axios
    .get(endpoint, { ...config, headers })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw error.response;
    });
};

const genericPut = <ResponseType>(
  endpoint: string,
  putParameters?: { [key: string]: unknown } | FormData,
  token?: string
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return axios
    .put(endpoint, putParameters, { headers })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw error.response;
    });
};

const queryGet = <ResponseType>(endpoint: string, query?: any, token?: string): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);
  const endpointUrl = query ? `${endpoint}?${query}` : endpoint;

  return axios
    .get(endpointUrl, { headers })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
};

const genericPatch = <ResponseType>(
  endpoint: string,
  postParameters: { [key: string]: unknown },
  token?: string
): Promise<ResponseType> => {
  const headers = createHeadersWithToken(token);

  return axios
    .patch(endpoint, postParameters, {
      headers,
    })
    .then((response: AxiosResponse<ResponseType>) => response.data)
    .catch((error: ApiHttpErrorType) => {
      throw new Error(error.response.status);
    });
};

const getGetErrorText = (err: number): string => {
  switch (err) {
    case 400:
      return "Something went wrong";
    case 401:
      return "You are not authorized";
    case 500:
      return "Something went wrong";
    default:
      return "Something went wrong";
  };
}

const getCreateErrorText = (err: number): string => {
  switch (err) {
    case 400:
      return "Fill in the form with correct data";
    case 401:
      return "You are not authorized";
    case 500:
      return "One or more item was not deleted";
    default:
      return "Something went wrong";
  };
}

const getDeleteErrorText = (err: number): string => {
  switch (err) {
    case 401:
      return "You are not authorized";
    case 500:
      return "One or more item was not deleted";
    default:
      return "Something went wrong";
  };
}

export { genericDelete, genericPost, genericPut, genericGet, genericPatch, queryGet, getGetErrorText, getCreateErrorText, getDeleteErrorText };
