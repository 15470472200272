import {useState, ChangeEvent} from 'react';

export const useInput = (initialValue:any)  => {
    const [value, setValue] = useState(initialValue);
    
    return {
      value,
      setValue,
      reset: (value?: string) => setValue(value? value : ""),
      bind: {
        value,
        onChange: (event :ChangeEvent<HTMLInputElement> ) :void => {
          setValue(event.target.value);
        }
      }
    };
  };

  export const useMultiselectInput = (initialValue:string[])  => {
    const [value, setValue] = useState<string[]>([]);
    
    return {
      value,
      setValue,
      reset: (value:string[]) => setValue(value),
      bind: {
        value,
        onChange: (event: React.ChangeEvent<{ value: unknown }>) :void => {
          setValue(event.target.value as string[]);
        }
      }
    };
  };

  export const useSelectInput = (initialValue:string)  => {
    const [value, setValue] = useState(initialValue);
    
    return {
      value,
      setValue,
      reset: (value:string) => setValue(value),
      bind: {
        value,
        onChange: (event: React.ChangeEvent<{ value: unknown }>) :void => {
          setValue(event.target.value as string);
        }
      }
    };
  };

  export const useCheckboxInput = (initialValue:boolean)  => {
    const [value, setValue] = useState(initialValue);
    
    return {
      value,
      setValue,
      reset: (value: boolean) => setValue(value),
      bind: {
        checked: value,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) :void => {
          setValue(event.target.checked);
        }
      }
    };
  };

  export const useRadioInput = (initialValue:string)  => {
    const [value, setValue] = useState(initialValue);
    
    return {
      value,
      setValue,
      bind: {
        value,
        onChange: (event:  React.ChangeEvent<HTMLInputElement>) :void => {
          setValue((event.target as HTMLInputElement).value);
        }
      }
    };
  };

  export const useNumberInput = (initialValue:number, min:number, max:number)  => {
    const [value, setValue] = useState(initialValue);

    const handleNumberInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const value = parseInt((event.target as HTMLInputElement).value);
      if (min <= value && value <= max) {
        setValue(value);
      };
  };
    
    return {
      value,
      setValue,
      bind: {
        value,
        onChange: (event:  React.ChangeEvent<HTMLInputElement>) :void => {
          handleNumberInputChange(event);
        }
      }
    };
  };