import React, { FormEvent, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormGroup, Link, Snackbar, Switch } from '@material-ui/core';
import { useCheckboxInput, useInput, useSelectInput } from '../hooks/input-hook';
import { authenticationService, ResponseMessage } from '../services/authentication.service';
import { Alert } from '@material-ui/lab';
import { useSessionContext } from '../contexts/SessionContext';
import { accountsService } from '../services/account-management';
import { AccountCreateRequest, AccountUpdateRequest } from '../services/account-management/types';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  switchBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface AddAccountFormProps {
  editedClientUuid?: string;
  handleClose: ()=>void;
  setAddAccountResponse: (response: null | ResponseMessage) => void;
};

export default function AddAccountForm(props: AddAccountFormProps) {
  const [sessionContext] = useSessionContext();
  const classes = useStyles();
  const { value: plan, bind: bindPlan, reset: resetPlan } = useSelectInput("standard");
  const { value: accountName, bind: bindAccountName, reset: resetAccountName } = useInput("");
  const { value: taxId, bind: bindTaxId, reset: resetTaxId } = useInput("");
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const { value: isLocked, bind: bindIsLocked, reset: resetIsLocked } = useCheckboxInput(false);
  const { value: isActivated, bind: bindIsActivated, reset: resetIsActivated } = useCheckboxInput(false);
  const plans = ["standard", "premium", "premiumPro"];

  const [ accountNameError, setAccountNameError ] = React.useState<boolean>(false);
  const [ taxIdError, setTaxId ] = React.useState<boolean>(false);
  const [ emailError, setEmailError ] = React.useState<boolean>(false);

  const {editedClientUuid, handleClose, setAddAccountResponse} = props;

  const [t] = useTranslation();

  const validationOk = () => {

    setAccountNameError(false);
    setTaxId(false);
    setEmailError(false);

    if (accountName.length === 0) {
      setAddAccountResponse({ status: "error", message: "Account name have to be provided" });
      setAccountNameError(true);
      return false;
    }

    if (taxId.length !== 10) {
      setAddAccountResponse({ status: "error", message: "Length of taxId have to be equal 10" });
      setTaxId(true);
      return false;
    }

    if (!/^[0-9]{10}$/.test(taxId)) {
      setAddAccountResponse({ status: "error", message: "TaxId must contains only numbers" });
      setTaxId(true);
      return false;
    }

    const mailformat = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!mailformat.test(email)) {
      setAddAccountResponse({ status: "error", message: "Wrong email address" });
      setEmailError(true);
      return false;
    }

    return true;
  }

  async function handleOnSubmit(e: FormEvent): Promise<any> {
    e.preventDefault();
    const dept = editedClientUuid ? {
      name: accountName,
      taxId,
      plan,
      email,
      isEnabled: !isLocked,
    } : {
      name: accountName,
      taxId,
      plan,
      email,
    };

    if (!validationOk()) return;
    
    setAddAccountResponse({ status: 'info', message: "Please wait" });

    const res = editedClientUuid ?
      await accountsService.updateAccount( editedClientUuid, dept as AccountUpdateRequest, sessionContext.token ) :
      await authenticationService.register(dept as AccountCreateRequest, sessionContext.token);
    
    setAddAccountResponse(res);

    if(res.status === "success") handleClose();
  };

  useEffect(() => {
    if (editedClientUuid) {
      accountsService.getAccount(editedClientUuid, sessionContext.token)
        .then(res => {
          if (!res) return;
          
          resetAccountName(res.name);
          resetTaxId(res.taxId);
          resetEmail(res.email);
          resetPlan(res.plan);
          resetIsActivated(res.isActivated);
          resetIsLocked(!res.isEnabled);
        })
        .catch(err => console.log(err))
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box pb={4} mt={2}>
          <Typography component="h1" variant="h5">
            {t("Insert account data")}
          </Typography>
        </Box>
        <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
          <TextField
            id="planSelect"
            select
            fullWidth
            label={t("Plan chosen")}
            size="small"
            SelectProps={{
              native: true,
            }}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            {...bindPlan}
          >
            {plans ? plans.map((option, nbr) => (
              <option key={option + nbr} value={option}>
                {option}
              </option>
            )) : ""}
          </TextField>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="accountName"
            label={t("Account name")}
            id="accountName"
            autoComplete="accountName"
            error={accountNameError}
            {...bindAccountName}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="taxId"
            label={t("NIP")}
            id="taxId"
            autoComplete="taxId"
            error={taxIdError}
            {...bindTaxId}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("Email Address")}
            name="email"
            autoComplete="email"
            error={emailError}
            {...bindEmail}
          />
          {
            editedClientUuid &&
            <FormGroup>
              <FormControlLabel
                  className={classes.switchBox}
                  control={<Switch {...bindIsActivated} name="activated-account" />}
                  label={t("Is activated")}
                  labelPlacement="start"
                  disabled
              />
            </FormGroup>
          }
          {
            editedClientUuid &&
            <FormGroup>
              <FormControlLabel
                  className={classes.switchBox}
                  control={<Switch {...bindIsLocked} name="locked-account" />}
                  label={t("Is locked")}
                  labelPlacement="start"
              />
            </FormGroup>
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t(editedClientUuid ? "Save" : "Create account")}
          </Button>
        </form>
      </div>
    </Container>
  );
}